import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/user/Login1.vue'
import Vragenlijst from '../views/Vragenlijst'
import Lijst from '../views/OverzichtVragenlijsten'
import AdminRoot from '../views/AdminRoot'

import tokenHelper from '../helpers/token.helper'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/RegisterSimple.vue')
  },
  {
    path: '/closed',
    name: 'closed',
    component: () => import(/* webpackChunkName: "about" */ '../views/ClosedLanding.vue')
  },
  {
    path: '/achtergrond',
    name: 'achtergrond',
    component: () => import(/* webpackChunkName: "about" */ '../views/Achtergrond.vue')
  },
  {
    path: '/overzicht',
    name: 'Overzicht',
    component: Lijst
  },
  {
    path: '/vragen/:lijstId',
    name: 'vragen',
    component: Vragenlijst
  },
  {
    path: '/404',
    name: 'Error404',
    component: () => import('../views/system/Error404.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import('../views/user/ForgotPassword.vue')
  },
  {
    path: '/admin',
    component: AdminRoot,
    children: [
      {
        path: '', redirect: 'instanties'
      },
      { name: 'Instanties', path: 'instanties', component: () => import('../views/admin/AdminInstanties.vue') },
      {
        path: 'instantie/:instantieId', component: () => import('../views/admin/SelectedInstantie.vue'), children: [
          {
            path: '', redirect: "Audits"
          },
          { name: 'Gebruikers', path: 'gebruikers', component: () => import('../views/admin/AdminGebruikers.vue') },
          { name: 'Audits', path: 'audits', component: () => import('../views/admin/AdminAudits.vue') },
          {
            path: 'audit/:auditId', component: () => import('../views/admin/SelectedAudit.vue'), children: [
              {
                path: '', redirect: "Lijsten"
              },
              {
                name: 'Gekoppeld', path: 'gekoppeld', component: () => import('../views/admin/AdminDeelnemers.vue')
              },
              {
                name: 'AuditDashboard', path: 'adashboard', component: () => import('../views/admin/AdminAuditDashboard.vue')
              },
              {
                name: 'AuditTemplate', path: 'atemplate', component: () => import('../views/admin/AdminAuditTemplate.vue')
              },
            ]
          }

        ]
      },

    ]

  },

]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/', '/login', '/register','/privacy','/forgot'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = (tokenHelper.getToken() != null);

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
});


export default router
