import axios from 'axios'
import router from "@/router"

/*
const api = axios.create({
  //'baseURL': 'https://localhost:44353',
  baseURL: `${process.env.VUE_APP_BASE_API_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  }
});*/

const api = axios.create({
    baseURL: "/api",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    }
});

api.interceptors.response.use(null, error => {
  if (error.response.status === 401) {
    if (error.response.request.responseURL.includes('api/user')) {
      return Promise.reject({status: "frank"});
    }
    router.push('/login');
    
  } else {

    return Promise.reject(error.response.data);
    //return Promise.reject(error.response.data.errors);
  }
});

export default api;
