import api from '../server/services/api.service'
import tokenHelper from '../../helpers/token.helper';

export default {

    async fetchGebruiker() {
        try {
            const { data } = await api.get(`user`, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            return (error);
        }
    },

    async fetchExampleData() {
        try {
            const { data } = await api.get(`/frank`);
            return data
        } catch (error) {
            console.log(error);
        }
    },
    async fetchLijsten() {
        try {
            const { data } = await api.get(`Audit/lijsten`, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            console.log(error);
        }
    },
    async fetchLijst(lijstId) {
        try {
            const { data } = await api.get(`Audit/lijsten/${lijstId}`, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            console.log(error);
        }
    },
    async fetchVragen(lijstId) {
        try {
            const { data } = await api.get(`Audit/vragen/${lijstId}`, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            console.log(error);
        }
    },
    async fetchAntwoorden(lijstId) {
        try {
            const { data } = await api.get(`Audit/antwoorden/${lijstId}`, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            console.log(error);
        }
    },
    async fetchEnums() {
        try {
            const { data } = await api.get(`Audit/enums`, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            console.log(error);
        }
    },
    async fetchPaginas(instantieId) {
        try {
            const { data } = await api.get(`Audit/paginas/${instantieId}`, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            console.log(error);
        }
    },
    async fetchOpleidingen() {
        try {
            const { data } = await api.get(`Audit/opleidingen`, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            console.log(error);
        }
    },


    async fetchAdminGebruikers(instantieId) {
        try {
            const { data } = await api.get(`admin/instanties/${instantieId}/gebruikers`, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            return (error);
        }
    },

    async addGebruiker(instantieId,obj) {
        return await api.post(`admin/instanties/${instantieId}/gebruikers`, obj, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
    },

    async updateGebruiker(instantieId,obj) {
        return await api.put(`admin/instanties/${instantieId}/gebruikers`, obj, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
    },

    /*
    async fetchAdminLijsten() {
        try {
            const { data } = await api.get(`admin/lijsten`, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            return (error);
        }
    },*/

    async fetchInstanties() {
        try {
            const { data } = await api.get(`admin/instanties`, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            return (error);
        }
    },

    
    async fetchInstantie(instantieId) {
        try {
            const { data } = await api.get(`admin/instanties/${instantieId}`, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            return (error);
        }
    },


    async fetchAudits(instantieId) {
        try {
            const { data } = await api.get(`admin/audits/${instantieId}`, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            return (error);
        }
    },

    
    async fetchAudit(instantieId,auditId) {
        try {
            const { data } = await api.get(`admin/instanties/${instantieId}/audit/${auditId}`, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            return (error);
        }
    },


    async addInstantie(obj) {
        return await api.post(`Admin/instanties`, obj, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
    },

    async updateInstantie(instantieId,obj) {
        return await api.put(`admin/instanties/${instantieId}`, obj, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
    },

    async fetchDeelnemersInstantieAudit(instantieId, auditId) {
        try {
            const { data } = await api.get(`admin/instanties/${instantieId}/audits/${auditId}/deelnemers`, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            return (error);
        }
    },

    async addDeelnemer(instantieId,auditId,obj) {
        return await api.post(`admin/instanties/${instantieId}/audits/${auditId}/deelnemers`, obj, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
    },


    async verwijderDeelnemer(instantieId,auditId,obj) {
        try {
            await api.post(`admin/instanties/${instantieId}/audits/${auditId}/deelnemers/delete`, obj, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
        } catch (error) {
            return (error);
        }
    },

    async openLijst(obj) {
        try {
            await api.post(`Admin/lijstenopen`, obj, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
        } catch (error) {
            return (error);
        }
    },

    async verwijderGebruiker(obj) {
      
        try {
            await api.post(`AuthManagement/Delete`, obj, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
        } catch (error) {
            return (error);
        }
    },

    async verwijderGebruiker2(obj) {
        try {
            await api.post(`Admin/gebruikerdel`, obj, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
        } catch (error) {
            return (error);
        }
    },

    async downloadLijst(lijstId) {
        try {
            return await api.get(`Document/vragenlijst?lijstId=${lijstId}`, { responseType: 'blob', headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
        } catch (error) {
            return (error);
        }
    },

    async fetchGraphVoortgangAudit(instantieId, auditId) {
        try {
            const { data } = await api.get(`graph/instanties/${instantieId}/audits/${auditId}/voortgang`, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            return (error);
        }
    },

    async fetchGraphGauges(instantieId, auditId) {
        try {
            const { data } = await api.get(`graph/instanties/${instantieId}/audits/${auditId}/guages`, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            return (error);
        }
    },

    async fetchGraphGauges2(instantieId, auditId) {
        try {
            const { data } = await api.get(`graph/instanties/${instantieId}/audits/${auditId}/guages2`, { headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            return (error);
        }
    },

    async mailInvite(obj) {
        
        try {
            const { data } = await api.post(`mail/invite`, {userName : obj.email},{ headers: { "Authorization": `Bearer ${tokenHelper.getToken()}` } });
            return data
        } catch (error) {
            alert(error)
            return (error);
        }
    },

    async otpStep1(obj, soort) {
        // verstuur mail met code
        try {
            const { data } = await api.post(`otp/otpstep1?soort=${soort}`, {userName : obj.email});
            return data
        } catch (error) {
            alert(error)
            return (error);
        }
    },


    async otpStep2(otp, soort) {
        // controleer code
        try {
            const { data } = await api.post(`otp/otpstep2?soort=${soort}`, {otp : otp});
            return data
        } catch (error) {
            alert(error)
            return (error);
        }
    },

    async forgot(obj) {
        // wijzig wachtwoord na doorlopen otp stappen
        try {
            const { data } = await api.post(`AuthManagement/forgot`, obj);
            return data
        } catch (error) {
            alert(error)
            return (error);
        }
    },



}