<template>
  <v-container fill-height>
    <v-img :src="require('../assets/vakbekwaamheid.png')" class="my-3" contain height="150" />

    <p class="text--primary">
      Volgens de Wet WOZ is het college van Burgemeester en Wethouders verantwoordelijk voor
      het inzetten van voldoende vakbekwame medewerkers. Daarnaast heeft de Waarderingskamer
      de Waarderingsinstructie opgesteld, waarin vakbekwaamheid één van de zes
      aandachtsgebieden is.
    </p>
    <p class="text--primary">
      Voor gemeenten en andere uitvoeringsorganisaties in de WOZ is het daarom belangrijk om de
      vakbekwaamheid van (potentiële) medewerkers te toetsen of om juist de eigen
      vakbekwaamheid aan te tonen.
    </p>
    <p class="text--primary">
      Het is voor gemeenten en andere uitvoeringsorganisaties in de WOZ dus van
      belang om de vakbekwaamheid van een (potentiële) medewerker te toetsen of
      juist om de eigen vakbekwaamheid aan te tonen.
    </p>
    <p class="text--primary">
      Om deze reden is er een applicatie ontwikkeld waarmee de vakbekwaamheid van medewerkers
      snel en overzichtelijk in beeld kan worden gebracht.
    </p>
    <p>
      Als u een uitnodiging heeft ontvangen, kunt u inloggen of registreren als nieuwe gebruiker.
    </p>

    <v-container v-if="gebruiker">
      <v-row justify="center" v-if="gebruiker.status != null">
        <router-link to="/login" class="subheading mx-3">Inloggen</router-link>
        |
        <router-link to="/register" class="subheading mx-3">Registreren</router-link>
      </v-row>
      <v-row justify="center" v-else>
        <router-link to="/closed" class="subheading mx-3">Naar uitleg</router-link>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Home",
  data: () => ({
    pages: [
      {
        omschrijving: "Inloggen",
        to: "/login",
      },
      {
        omschrijving: "Registreren",
        to: "/register",
      },
      {
        omschrijving: "Registreren",
        to: "/register",
      },
    ],
  }),
  computed: {
    ...mapState({
      gebruiker: "gebruiker",
    }),
  },
};
</script>
