
import api from './api.service'
import tokenHelper from '../../../helpers/token.helper'

import store from "@/store"

export const userService = {
    login,
    login1,
    loginCheck,
    register,
    logout,
    setToken
};


async function login(email, password) {

    try {

        tokenHelper.removeToken();

        const response = await api.post(`AuthManagement/Login`, { email: email, password: password });

        if (!response.status === 200) {
            return { status: "nok" }
        }

        tokenHelper.saveToken(response.data.token);

        store.dispatch("fetchGebruiker");


        return { status: "ok" }

    } catch (error) {
        return Promise.reject(error);
    }
}

async function login1(email, password) {

    try {

        return await api.post(`AuthManagement/Login`, { email: email, password: password });

 
    } catch (error) {
        return Promise.reject(error);
    }
}

async function loginCheck(ticket) {

    try {

        tokenHelper.removeToken();

        const response = await api.post(`AuthManagement/LoginCheck`, { ticket: ticket });

        if (!response.status === 200) {
            return { status: "nok" }
        }

        tokenHelper.saveToken(response.data.token);

        store.dispatch("fetchGebruiker");


        return { status: "ok" }

    } catch (error) {
        return Promise.reject(error);
    }
}


function setToken(token) {

    tokenHelper.removeToken();
    tokenHelper.saveToken(token);
    store.dispatch("fetchGebruiker");
}

function logout() {
    tokenHelper.removeToken();
}


async function register(email, wachtwoord) {

    try {
        const response = await api.post(`AuthManagement/register`, { name: email, email: email, password: wachtwoord });

        if (!response.status === 200) {
            return { status: "nok" }
        }

        return { status: "ok" }

    } catch (error) {
        return Promise.reject(error);
    }



}