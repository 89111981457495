<template>
  <div v-if="selectedLijst">

    <div v-if="selectedLijst.status === 'open'">
      <ul v-show="false">
        <li @click="selectedPageIndex = index" v-for="(pagina, index) in paginas" :key="index">
          {{ pagina.naam }}
        </li>
      </ul>

      <v-card flat v-if="fetched == true">
        <v-toolbar color="#6497b1" dark extended flat>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <div v-if="false">
                <v-app-bar-nav-icon dark icon v-bind="attrs" v-on="on">
                </v-app-bar-nav-icon>
              </div>
            </template>

            <v-list>
              <v-list-item v-for="(pagina, index) in paginas" @click="selectedPageIndex = index" :key="index">
                <v-list-item-title>{{ pagina.naam }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>

        <v-card class="mx-auto" max-width="80%" style="margin-top: -64px">
          <v-toolbar flat>
            <v-toolbar-title>
              {{ selectedPage && selectedPage.naam }}
            </v-toolbar-title>
          </v-toolbar>

          <v-divider></v-divider>

          <v-card-text>

            <component v-if="vragenlijst.length > 0" ref="current" :page="selectedPage" :paginaId="selectedPage.id"
              :inleiding="selectedPage.inleiding" @pageValidChanged="setPageValid($event)" :is="asyncComponent">
            </component>

            <v-container>
              <v-row justify="space-between" class="mt-5">
                <v-btn :disabled="selectedPageIndex == 0" color="blue" @click="navigatePrevious"><span
                    style="color: white">
                    Vorige</span></v-btn>
                <v-btn color="blue" :disabled="!pageValid" @click="navigateNext"><span style="color: white">
                    Volgende
                  </span>
                </v-btn>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-card>

      <v-dialog v-model="dialogIndienen" max-width="400px">
        <div class="text-center">
          <v-card>
            <v-card-title>
              <span class="headline">Indienen</span>
            </v-card-title>
            <v-card-text>
              <p class="text--primary">U staat op het punt om de vragenlijst in te dienen. Na het indienen kunt u niet meer bij de gegevens om ze aan te passen.</p>
              <p class="text--primary">Klik op de knop "indienen" om verder te gaan</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogIndienen = false">Annuleren</v-btn>
              <v-btn color="blue darken-1" @click="auditIndienen">Indienen</v-btn>
            </v-card-actions>
          </v-card>
        </div>

      </v-dialog>

    </div>
    <div v-else-if="selectedLijst.status === 'ingediend'">
      <v-card>
        <v-card-title><span class="headline">Vragenlijst is al ingediend</span></v-card-title>

        <v-card-text>
          <p class="text--primary">
            De vragenlijst is ingediend en kan niet meer aangepast worden. Mocht u toch nog aanpassingen willen doorvoeren, neem dan contact op met de beheerder van deze website binnen uw organisatie.
          </p>

          <p class="text--primary">U kunt de audit via onderstaande knop downloaden</p>
        </v-card-text>

        <v-card-actions>
          <v-btn block @click="genereerBestand" tile color="info">
            <v-icon left> mdi-file</v-icon>
            Vragenlijst downloaden
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <div v-else-if="selectedLijst.status === 'verstuurd'">
      <v-card>
        <v-card-title>Vragenlijst is verstuurd</v-card-title>

        <v-card-text>
          <p class="text--primary">
            De vragenlijst is ingediend en kan niet meer aangepast worden. Mocht
            u toch nog aanpassingen willen doorvoeren neem dan contact op met
            vakbekwaamheid WOZ
          </p>

          <p class="text--primary">
            Klik op onderstaande link om naar het overzicht van de vragenlijsten
            te gaan
          </p>

          <router-link to="/overzicht" class="subheading mx-3">Vragenlijst</router-link>
        </v-card-text>

        <v-card-actions>
          <v-btn block @click="genereerBestand" tile color="info">
            <v-icon left> mdi-file</v-icon>
            Vragenlijst downloaden
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <v-dialog v-model="dialogSpinner" max-width="350px">
      <div class="text-center">
        <v-card>
          <v-card-title class="justify-center">Document wordt gegenereerd</v-card-title>
          <v-card-text>
            <v-progress-circular indeterminate color="primary" :size="70" :width="7"></v-progress-circular>
          </v-card-text>
        </v-card>
      </div>

    </v-dialog>
  </div>
</template>

<script>

import client from "api-client";

import api from "../api/server/services/api.service";
import tokenHelper from "../helpers/token.helper";

import AsyncError from "@/components/AsyncError.vue";
import AsyncLoading from "@/components/AsyncLoading.vue";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Vragenlijst",
  data: () => ({
    e1: 0,
    pageValid: false,
    asyncComponent: null,
    selectedPageIndex: null,
    fetched: false,
    dialogSpinner: false,
    dialogIndienen: false
  }),
  methods: {
    ...mapActions([
      "fetchVragen",
      "fetchAntwoorden",
      "fetchEnums",
      "fetchLijsten",
      "fetchPaginas",
      "fetchLijst",
    ]),
    auditopslaan() {
      if (this.$refs.current.submitNext) this.$refs.current.submitNext();

      api
        .post(
          `Audit/lijsten/${this.$route.params.lijstId}`,
          this.antwoorden,
          { headers: { Authorization: `Bearer ${tokenHelper.getToken()}` } }
        )
        .then(() => {
        });
    },
    auditopslaanPrev() {

      if (this.$refs.current.submitPrev) this.$refs.current.submitPrev();

      api
        .post(
          `Audit/lijsten/${this.$route.params.lijstId}`,
          this.antwoorden,
          { headers: { Authorization: `Bearer ${tokenHelper.getToken()}` } }
        )
        .then(() => {
        });
    },
    auditIndienen() {
      api
        .post(
          `Audit/lijsten/${this.$route.params.lijstId}/indienen`, null, { headers: { Authorization: `Bearer ${tokenHelper.getToken()}` } }
        )
        .then(() => {
          this.selectedLijst.status = "verstuurd";
        });
    },
    setPageValid(e) {
      this.pageValid = e;
    },

    navigateNext() {

      const isLast = (this.selectedPageIndex == this.paginas.length - 1)

      this.auditopslaan(!isLast);

      if (isLast) {
        this.dialogIndienen = true
        return
      }
      this.selectedPageIndex += 1;
    },

    navigatePrevious() {
      this.auditopslaanPrev();
      this.pageValid = true;
      this.selectedPageIndex -= 1;
    },

   
    genereerBestand() {
      this.dialogSpinner = true
      client.downloadLijst(this.selectedLijst.id).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `${this.selectedLijst.id}.pdf`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.dialogSpinner = false;
      }).catch(() => {
        this.dialogSpinner = false
      });
    },
    loadAsyncComponent() {
      this.asyncComponent = () => ({
        component: import(`@/components/${this.selectedPage.component}`),
        loading: AsyncLoading,
        error: AsyncError,
        delay: 100,
        timeout: 3000,
      });
    },
  },
  computed: {
    ...mapState({
      antwoorden: "antwoorden",
      lijsten: "lijsten",
      paginas: "paginas",
      selectedLijst: "selectedLijst",
    }),

    ...mapGetters({
      vragenlijst: "vragenlijst",
    }),

    selectedPage() {
      return this.paginas[this.selectedPageIndex];
    },

  },

  watch: {
    selectedPage: function () {
      this.loadAsyncComponent();
    },
    selectedLijst: function () {

      if (this.selectedLijst.status === "open") {
        this.fetchVragen(this.$route.params.lijstId);
        this.fetchEnums();

        this.fetchPaginas(this.$route.params.lijstId);

        this.fetchAntwoorden(this.$route.params.lijstId).then(() => {
          this.fetched = true;
        });

        this.selectedPageIndex = 0;
      }
    },
    "$route.params.lijstId": {
      handler: function (lijstId) {
        this.fetchLijst(lijstId);
      },
      deep: true,
      immediate: true,
    },
  }
};
</script>