import Vue from 'vue'
import Vuex from 'vuex'

import client from 'api-client'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    gebruiker : null,
    vragen: [],
    antwoorden: [],
    lijsten :[],
    selectedLijst : {},
    enums:[],
    paginas:[]
  },
  mutations: {
    setLijsten(state, lijsten) {
      state.lijsten = lijsten;
    },
    setLijst(state, lijst) {
      state.selectedLijst = lijst;
    },
    setVragen(state, vragen) {
      state.vragen = vragen;
    },
    setAntwoorden(state, antwoorden) {
      state.antwoorden = antwoorden;
    },
    setEnums(state,enums){
      state.enums = enums;
    },
    setPaginas(state,paginas){
      state.paginas = paginas;
    },
    setGebruiker(state,gebruiker){
      state.gebruiker = gebruiker;
    },
    updateAntwoorden(state, antwoorden) {

      antwoorden.forEach((fe) => {
        const item = state.antwoorden.find((f) => f.vraag_id === fe.id);
        if (item != null) {
          Object.assign(item, {
            reactie: fe.reactie,
            vraag_id: fe.id,
          });
        }
        else {
          state.antwoorden.push({
            reactie: fe.reactie,
            vraag_id: fe.id,
          });
        }
      });
    }
  },
  getters: {
    vragenlijst: (state) => {

      return state.vragen.map((v) => {
        const { reactie } = {
          ...state.antwoorden.find((a) => a.vraag_id == v.id),
        };
        return { ...v, reactie: reactie || null };
      });

    },
    getVraag:(state)=>(vraagId)=>{
      return state.vragen.find(v=>v.id===vraagId)
    },
    getInstantie:(state)=>(instantieId)=>{
      return state.gebruiker?.instanties?.find(i=>i.id===instantieId)
    }

  },
  actions: {
    fetchLijsten({ commit }) {
      return client.fetchLijsten().then(data => commit('setLijsten', data))
    },
    fetchLijst({ commit },lijstId) {
      return client.fetchLijst(lijstId).then(data => commit('setLijst', data))
    },
    fetchVragen({ commit },lijstId) {
      return client.fetchVragen(lijstId).then(data => commit('setVragen', data))
    },
    fetchAntwoorden({ commit },lijstId) {
      return client.fetchAntwoorden(lijstId).then(data => commit('setAntwoorden', data))
    },
    fetchEnums({ commit }) {
      return client.fetchEnums().then(data => commit('setEnums', data))
    },
    fetchPaginas({ commit },lijstId) {
      return client.fetchPaginas(lijstId).then(data => commit('setPaginas', data))
    },
    fetchGebruiker({ commit }) {
      return client.fetchGebruiker().then(data => commit('setGebruiker', data))
    },
    updateAntwoorden({ commit }, payload) {
      commit('updateAntwoorden', payload)
    }
  },
  modules: {
  }
})
