<template>
  <div v-if="gebruiker">
    <router-view></router-view>
  </div>
  <div v-else>De gebruiker heeft niet de juiste autorisatie</div>
</template>


<script>
import { mapState } from "vuex";

export default {
  name: "AdminRoot",
  computed: {
    ...mapState({
      gebruiker: "gebruiker",
    }),
  },
};
</script>