<template>
  <v-container justify="center" fluid>
    <v-card v-if="step == 1" class="mx-auto" style="max-width: 400px;">
      <v-card-title>Inloggen</v-card-title>
      <v-card-text class="text--primary">
        Vul hieronder uw e-mailadres en wachtwoord in.
      </v-card-text>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-layout row wrap justify-center>
              <v-flex xs12>
                <v-text-field autocomplete="username" v-model="email" :rules="emailRules" label="E-mail"
                  required></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-text-field autocomplete="current-password" v-model="wachtwoord" :rules="required" type="password"
                  label="Wachtwoord" required></v-text-field></v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-btn block :disabled="!valid" color="primary" class="mr-4" @click="login">
              <span style="color:white">
                Inloggen
              </span>

            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>

      <v-card-text v-if="errors.length > 0">
        Uw <b>e-mailadres </b>en/of <b>wachtwoord </b> is niet correct. Probeert u het nog eens. Indien u uw wachtwoord
        bent vergeten, dan kunt u via onderstaande link uw wachtwoord resetten</v-card-text>

      <v-card-text>
        <router-link to="/forgot">Wachtwoord vergeten</router-link>
      </v-card-text>
    </v-card>
    <v-card v-else-if="step == 2" style="max-width: 400px" class="ma-auto">
      <v-card-title>Verificatie vereist</v-card-title>

      <v-card-text>

        <p>Voer deze verificatiestap uit om door te gaan. We hebben een code verstuurd naar {{ email }}. Voer
          de code hieronder in.</p>

        <div class="ma-auto position-relative" style="max-width: 300px">

          <b>Code invoeren</b>
          <v-otp-input v-model="otp.model" :disabled="otp.loading" @finish="onFinish"></v-otp-input>
          <v-overlay absolute :value="otp.loading">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-overlay>
        </div>
    
        <div class="text--caption">Type of kopieer/plak de code uit de email.</div>
<br>
<p>Het sturen van de code kan 1-2 minuten duren. Geen mail ontvangen? Kijk dan of de mail in de ongewenste mail terecht is gekomen.</p>


      </v-card-text>
      <v-card-text v-if="errors.length > 0">
       De ingegeven code is niet correct. Toets nogmaals de code in of klik <a href="#" @click="back2Begin()">hier</a> om opnieuw aan te melden.</v-card-text>
    </v-card>

  </v-container>
</template>

<script>

import { userService } from "../../api/server/services/userService";
import client from "api-client";

export default {
  name: "Login",
  data: function () {
    return {
      valid: false,
      email: "",
      wachtwoord: "",
      step: 1,
      otp: {
        model: '',
        loading: false,
        expectedOtp: '',
      },
      emailRules: [
        (v) => !!v || "Waarde is verplicht",
        (v) => /.+@.+\..+/.test(v) || "Geen geldig emailadres",
      ],
      required: [(v) => !!v || "Waarde is verplicht"],
      token: null,
      errors: [],
    };
  },
  methods: {
    onFinish(rsp) {
      this.checkOtp(rsp)
    },
    back2Begin(){
      this.email = ""
      this.wachtwoord = ""
      this.errors = []
      this.valid = false
      this.otp.model = ''
      this.step =1
    },
    login() {
      userService
        .login1(this.email, this.wachtwoord)
        .then(() => {
          //r.data.otp
          this.step = 2
        })
        .catch((err) => {
          console.log(err)
          this.errors = err.errors;
        });
    },
    checkOtp(otp) {
      client.otpStep2(otp, 'login').then((res) => {
        if (res.id != null) {
          userService.loginCheck(res.id).then((d) => {
            if (d.status === "ok") {
              this.$router.push("closed");
            }
          }).catch((err) => {
            console.log(err)
            this.errors = err.errors;
          });
        } else {
          this.errors.push("Code not correct")
        }
      })
    },
    uitloggen() {
      userService.logout();
    },
  },
};
</script>