<template>
  <v-container>
    <h1 class="display-1 mb-3">Vragenlijsten</h1>

    <v-list v-if="lijsten.length > 0">
      <p>
        Hieronder vindt u een overzicht van alle <b>vragenlijsten</b> waarvoor u een uitnodiging hebt
        ontvangen. Een vragenlijst kan de status “open” of “ingediend” hebben. Als de status
        “ingediend” is, kunt u deze niet meer invullen of wijzigen. U kunt echter wel
        een <b>PDF</b> downloaden met de ingevulde antwoorden.
      </p>

      <v-list-item v-for="lijst in lijsten" :key="lijst.id" :to="{ name: 'vragen', params: { lijstId: lijst.id } }">
        <v-list-item-avatar>
          <v-icon v-if="lijst.status === 'open'" class="color : green" dark>
            mdi-forum
          </v-icon>
          <v-icon v-else-if="lijst.status === 'ingediend'" class="color : blue" dark>
            mdi-check
          </v-icon>
          <v-icon v-else-if="lijst.status === 'verstuurd'" class="color : blue" dark>
            mdi-check
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ lijst.klant }}</v-list-item-title>
          <v-list-item-subtitle>{{ lijst.status }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <div v-else>
      <p>
        U bent wel geregistreerd maar er is nog geen vragenlijst gekoppeld aan
        uw naam.
      </p>

      <p>Neem contact op met de beheerder van deze
website binnen uw organisatie.</p>
    </div>
  </v-container>
</template>


<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "OverzichtVragenlijsten",
  methods: {
    ...mapActions(["fetchLijsten"]),
  },
  computed: {
    ...mapState({
      lijsten: "lijsten",
    }),
  },
  created() {
    this.fetchLijsten();
  },
};
</script>