<template>
  <v-app>
    <v-app-bar app color="blue" dense dark>
      <router-link to="/" class="home">Vakbekwaamheid</router-link>

      <v-spacer></v-spacer>

      <div v-if="gebruiker">
        <div v-if="gebruiker.status != null">
          <router-link to="/login" class="aut">Inloggen</router-link>
        </div>
        <div v-else>
          <v-btn
            v-if="showCog"
            icon
            color="white"
            to="/admin"
            small
            plain
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
          {{ gebruiker.username }}

          | <a @click="uitloggen" class="aut">uitloggen</a>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <v-container fluid style="maxWidth:960px" class="pa-4 pa-sm-6 pa-md8" justify="center">
        <router-view />
      </v-container>
    </v-main>

    <v-footer  app>
      <span>&nbsp; &copy; {{year}} &nbsp;| &nbsp; Versie {{ version }} |  <router-link to="/privacy" >Privacy statement</router-link></span> 
      
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { userService } from "./api/server/services/userService";

export default {
  name: "App",
  methods: {
    ...mapActions(["fetchGebruiker"]),
    uitloggen() {
      userService.logout();
      this.fetchGebruiker();
      this.$router.push("/");
    },
  },
  computed: {
    ...mapState({
      gebruiker: "gebruiker",
    }),
    showCog: function(){
      return this.gebruiker?.has_admin || this.gebruiker?.instanties?.filter((r)=>r.hasUserAdmin || r.hasAudit).length>0
    },
    version(){
      return process.env.VUE_APP_VERSION;
    },
    year(){
      return new Date().getFullYear();
    }
  },
  created() {
    this.fetchGebruiker();
  },
};
</script>


<style scoped>
.home {
  color: #ffffff;
  text-decoration: none;
}

.aut {
  color: #ffffff;
}
</style>